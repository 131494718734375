import { Link } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { isMobile } from "react-device-detect/main"
import TrackVisibility from "react-on-screen"
import CGPBoardComponent from "../components/board/board.component"
import CGPBoxComponent from "../components/board/box.component"
import Layout from "../components/containers/layout"
import Seo from "../components/containers/seo"
import StatisticsComponent from "../components/specifics/statistics.component"
import CGPTransitionRowFixedComponent from "../components/transitions/components/transition-row-fixed.component"
import CGPTransitionRowSpawnComponent from "../components/transitions/components/transition-row-spawn.component"
import CGPTransitionRowComponent from "../components/transitions/transition-row.component"
import { getRoute, ROUTES_ID } from "../constants/routes.contants"
import "../styles/a-propos.css"

const APropos = () => {
  const route = getRoute(ROUTES_ID.APROPOS)

  const [qualitySelect, setqualitySelect] = useState(-1)
  const isQualitySelected = qualityNumber => qualityNumber === qualitySelect

  return (
    <>
      <Seo title={route.screenTitle} />
      <Layout>
        <CGPTransitionRowComponent className="background-main-green-color presentation-row">
          <CGPTransitionRowFixedComponent className="column flex-center">
            <StaticImage
              className="photo-seb"
              src="../images/sebastien.png"
              alt="Sébastien profil"
            />
            <p className="flex-center">
              <span className="main-color">Sébastien OUTREVILLE</span>
              <br />
              fondateur de ACI
            </p>
          </CGPTransitionRowFixedComponent>

          <CGPTransitionRowFixedComponent className="column flex-center">
            <p className="guillemets x-large justify">
              Le conseil en investissement financier est un statut qui me permet
              de vous proposer toutes les solutions, financières et
              immobilières, qui permettent de créer, d'optimiser ou de
              transmettre votre patrimoine.
            </p>
          </CGPTransitionRowFixedComponent>
        </CGPTransitionRowComponent>

        <CGPTransitionRowComponent>
          <CGPTransitionRowSpawnComponent
            className="row flex-center background-main-grey-color text dynamic full-width"
            disable={!isQualitySelected(-1)}
          >
            <p className="guillemets x-large justify">
              Mes valeurs : écoute, éthique, expertise et fiabilité
              <br />
              Ces 4 valeurs guident mon conseil en investissement. →
              <br />
              <i>Plus de détail en pointant chaque valeur à droite !</i>
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="row flex-center background-main-grey-color text dynamic left-side"
            disable={!isQualitySelected(0)}
          >
            <p className="guillemets x-large justify">
              Parce que je ne peux pas vous proposer des solutions patrimoniales
              sans maîtriser votre situation et vos projets de vie, l'écoute est
              une étape indispensable et liminaire à tout conseil.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="row flex-center background-main-grey-color text dynamic left-side"
            disable={!isQualitySelected(1)}
          >
            <p className="guillemets x-large justify">
              L'éthique, c'est tout simplement faire passer vos intérêts avant
              quoique ce soit d'autre. Et un investisseur satisfait est un
              investisseur qui me recommandera. C'est mon business model.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="row flex-center background-main-grey-color text dynamic left-side"
            disable={!isQualitySelected(2)}
          >
            <p className="guillemets x-large justify">
              Bien sûr un conseil de qualité s'appuie sur une expertise du
              domaine. La formation nécessaire pour devenir conseiller en
              investissements financiers mais aussi la formation continue me
              permettent d'être précis sur mon domaine d'activité mais également
              d'évoluer au rythme des lois et des opportunités.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="row flex-center background-main-grey-color text dynamic left-side"
            disable={!isQualitySelected(3)}
          >
            <p className="guillemets x-large justify">
              Enfin, parce que les investissements se font dans la durée et
              qu'avec le temps tout change (l'environnement mais également vos
              projets !), un bon suivi est indispensable, et vous devez pouvoir
              compter sur ma fiabilité au fil des années.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowFixedComponent className="background-main-color quality-board">
            <CGPBoardComponent>
              <CGPBoxComponent
                onEnter={() => setqualitySelect(0)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage src="../images/ecoute.png" alt="l'écoute" />
              </CGPBoxComponent>

              <CGPBoxComponent
                onEnter={() => setqualitySelect(1)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage src="../images/ethique.png" alt="l'éthique" />
              </CGPBoxComponent>

              <CGPBoxComponent
                onEnter={() => setqualitySelect(2)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage src="../images/expertise.png" alt="l'expertise" />
              </CGPBoxComponent>

              <CGPBoxComponent
                onEnter={() => setqualitySelect(3)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage src="../images/fiabilite.png" alt="la fiabilité" />
              </CGPBoxComponent>
            </CGPBoardComponent>
          </CGPTransitionRowFixedComponent>
        </CGPTransitionRowComponent>

        <div className="row">
          <div className="column a-propos-keys-specific-container">
            <p className="main-color xx-large">
              Architecture ouverte
              <br />
              Vision globale
              <br />
              Pédagogie
              <br />
              Couverture nationale
              <br />
              Relation durable
            </p>
          </div>

          <div className="column a-propos-right-container flex-center">
            <h3 className="text xx-large">Atouts</h3>
            <p className="text large">
              Pour un conseil de qualité, je travaille autour de plusieurs
              atouts. Proposer tous les produits, pour ne pas biaiser mon
              analyse. Fonctionner en architecture ouverte, autrement dit
              choisir les produits selon leurs performances. Pouvoir vous
              accompagner sur l’ensemble du territoire. Expliquer les solutions
              patrimoniales, pour que vous maitrisiez vos investissements. Et
              bien sûr travailler dans la durée.
              <br />
              Et si vous voulez en découvrir un peu plus, rien de tel que{" "}
              <u>
                <a
                  className="main-color"
                  href="/news"
                  onClick={() =>
                    window.gtag("event", "navigation", {
                      event_category: "page-content",
                      event_label: "news",
                    })
                  }
                >
                  visionner mes conférences
                </a>
              </u>
            </p>
          </div>
        </div>

        <div className="background-main-green-color column">
          {isMobile ? (
            <StatisticsComponent />
          ) : (
            <TrackVisibility partialVisibility>
              <StatisticsComponent />
            </TrackVisibility>
          )}
        </div>

        <div className="column">
          <h2>Services Proposés</h2>
          <div className="a-propos-services-proposes row flex-space">
            <div className="column flex-center a-propos-container">
              <h4 className="main-color">Bilan patrimonial</h4>
              <p className="text-ligth justify">
                Ma volonté est de vous offrir ce bilan patrimonial. Parce qu’il
                est indispensable pour élaborer une stratégie d’investissement
                et comprendre les solutions préconisées pour atteindre vos
                objectifs, ou tout simplement prendre les bonnes décisions sur
                vos investissements. Et comme ce sont souvent les projets d’une
                vie, j’ai à cœur de vous aider à faire les bons choix. Pour
                réaliser votre bilan patrimonial, 2 rdv seront nécessaires : 1
                pour comprendre votre situation et vos projets, l’autre pour
                vous présenter le bilan et les préconisations.
              </p>
            </div>

            <div className="background-main-grey-color column flex-center a-propos-container">
              <h4 className="main-color">Mise en place de solutions</h4>
              <p className="text-ligth justify">
                Le premier frein à l’investissement, c’est le manque de
                connaissances. Le second, c’est parce qu’on ne sait pas par où
                commencer. La mise en place est donc un moment clé de
                l’investissement. C’est lors de cette mise en place que mes
                partenaires me rétro-commissionnent, ce qui constitue la majeure
                partie de ma rémunération. Ces commissions proviennent des frais
                que bien souvent vous payez... sans avoir de service associé !
                D’où l’intérêt de ne pas investir seul. Mon accompagnement
                s’applique aussi bien pour les placements financiers que
                l’immobilier ou le financement.
              </p>
            </div>

            <div className="column flex-center a-propos-container">
              <h4 className="main-color">Sujets d’expertise</h4>
              <p className="text-ligth justify">
                Pour les bilans successoraux, la protection de la famille, des
                allocations d’actifs, une aide à la déclaration fiscale ou
                d’autres sujets qui ne s’accompagnement de la mise en place de
                solutions, je peux également intervenir en facturant cette fois
                ci mon analyse. Devis sur demande. Je découvre souvent ces
                besoins lors du bilan patrimonial, c’est alors l’occasion d’en
                parler avec vous. Mais dans la majorité des cas, vous n’avez pas
                besoin d’aller si loin !
              </p>
            </div>
          </div>
          <Link
            to={"/conseils/"}
            className="filled know-more"
            onClick={() =>
              window.gtag("event", "navigation", {
                event_category: "page-content",
                event_label: "conseils",
              })
            }
          >
            EN SAVOIR PLUS
          </Link>
        </div>
      </Layout>
    </>
  )
}

export default APropos
