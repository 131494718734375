import React from "react"
import RiseComponent from "../shared/rise.component"

const maxInvestisseurs = 76
const maxEuros = 5389399
const maxBilans = 112

export default function StatisticsComponent({ isVisible = true }) {
  return (
    <div className="column statistics">
      {isVisible && (
        <>
          <h2>Ils me font confiance</h2>
          <div className="row flex-space">
            <div className="dinamyc-number">
              <RiseComponent max={maxInvestisseurs} />
              <p className="large">
                nombre <br />
                d'investisseurs
              </p>
            </div>
            <div className="dinamyc-number">
              <RiseComponent max={maxEuros} suffix=" &euro;" />
              <p className="large">
                euros <br /> investis
              </p>
            </div>
            <div className="dinamyc-number">
              <RiseComponent max={maxBilans} />
              <p className="large">
                bilans patrimoniaux
                <br /> offerts
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
