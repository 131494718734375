import React, { useEffect, useState } from "react"

const percent = (_percent, _max) => Math.round((_max * _percent) / 100)

export default function RiseComponent({ max, suffix }) {
  const [theNumber, settheNumber] = useState(0)

  useEffect(() => {
    let timeId
    const rest = max - theNumber

    if (rest > percent(50, max)) {
      timeId = setTimeout(() => {
        settheNumber(prev => {
          const newOne = Math.round((prev += percent(10, max)))
          return newOne > max ? max - percent(5, max) : newOne
        })
      }, 50)
    } else if (rest > percent(30, max)) {
      timeId = setTimeout(() => {
        settheNumber(prev => {
          const newOne = Math.round((prev += percent(5, max)))
          return newOne > max ? max - percent(3, max) : newOne
        })
      }, 100)
    } else if (rest > percent(10, max)) {
      timeId = setTimeout(() => {
        settheNumber(prev => {
          const newOne = Math.round((prev += percent(2, max)))
          return newOne > max ? max - percent(2, max) : newOne
        })
      }, 150)
    } else {
      timeId = setTimeout(() => {
        settheNumber(max - (rest > 4 ? 3 : rest > 0 ? rest - 1 : 0))
      }, 300)
    }
    return () => {
      clearTimeout(timeId)
    }
  }, [theNumber, max])
  return (
    <p className="number xx-large">
      {theNumber.toLocaleString()}
      {suffix || ""}
    </p>
  )
}
